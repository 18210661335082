import merge from 'lodash/merge';
import { LENDERS } from 'applicationConstants';
import cloneDeep from 'lodash/cloneDeep';
import generalStrings from './generalStrings.json';
import gsStrings from './gsStrings.json';
import allegroStrings from './allegroStrings.json';
import alphaeon2Strings from './alphaeon2Strings.json';
import splititStrings from './splitItStrings.json';
import proceedStrings from './proceedStrings.json';
import affStrings from './affStrings.json';
import uga2Strings from './uga2Strings.json';
import uga3Strings from './uga3Strings.json';
import clearGageStrings from './clearGageStrings.json';
import hfdStrings from './hfdStrings.json';
import hfdServicingStrings from './hfdServicingStrings.json';
import hfdSecondStrings from './hfdSecondStrings.json';
import greatamericaStrings from './greatamericaStrings.json';
import varidiStrings from './varidiStrings.json';
import genesisStrings from './genesisStrings.json';
import patientfiStrings from './patientfiStrings.json';
import powerpayStrings from './powerpayStrings.json';
import allyStrings from './allyStrings.json';
import idealStrings from './idealStrings.json';
import ugaPrimeStrings from './ugaPrimeStrings.json';
import fortivaStrings from './fortivaStrings.json';
import coveredCareStrings from './coveredCareStrings.json';
import coveredCareExtraStrings from './coveredCareExtraStrings.json';
import coveredCarePrimeString from './coveredCarePrimeString.json';
import carecreditStrings from './carecreditStrings.json';
import clarityPayStrings from './clarityPayStrings.json';
import clarityPay1Strings from './clarityPay1Strings.json';
import clarityPay2Strings from './clarityPay2Strings.json';
import clarityPay3Strings from './clarityPay3Strings.json';
import clarityPay4Strings from './clarityPay4Strings.json';
import snapStrings from './snapStrings.json';

export default {
  general: generalStrings,
  [LENDERS.greensky]: gsStrings,
  [LENDERS.patientfi]: patientfiStrings,
  [LENDERS.snap]: snapStrings,
  [LENDERS.allegro]: allegroStrings,
  [LENDERS.alphaeon2]: alphaeon2Strings,
  [LENDERS.splitit]: splititStrings,
  [LENDERS.fortiva]: fortivaStrings,
  [LENDERS.proceed]: proceedStrings,
  [LENDERS.claritypay]: clarityPayStrings,
  [LENDERS.claritypay_1]: {
    ...clarityPayStrings,
    ...clarityPay1Strings,
  },
  [LENDERS.claritypay_2]: {
    ...clarityPayStrings,
    ...clarityPay2Strings,
  },
  [LENDERS.claritypay_3]: {
    ...clarityPayStrings,
    ...clarityPay3Strings,
  },
  [LENDERS.claritypay_4]: {
    ...clarityPayStrings,
    ...clarityPay4Strings,
  },
  [LENDERS.covered_care]: coveredCareStrings,
  [LENDERS.covered_extra]: {
    ...coveredCareStrings,
    ...coveredCareExtraStrings,
  },
  [LENDERS.covered_prime]: {
    ...coveredCareStrings,
    ...coveredCarePrimeString,
  },
  [LENDERS.carecredit]: carecreditStrings,
  [LENDERS.aff]: affStrings,
  [LENDERS.uga2]: uga2Strings,
  [LENDERS.uga3]: {
    ...uga2Strings,
    ...uga3Strings,
  },
  [LENDERS.uga_prime]: {
    ...uga2Strings,
    ...ugaPrimeStrings,
  },
  [LENDERS.cleargage]: clearGageStrings,
  [LENDERS.hfd]: hfdStrings,
  [LENDERS.hfd_second]: merge(cloneDeep(hfdStrings), hfdSecondStrings),
  [LENDERS.hfd_servicing]: merge(cloneDeep(hfdStrings), hfdServicingStrings),
  [LENDERS.ideal]: merge(cloneDeep(hfdStrings), idealStrings),
  [LENDERS.greatamerica]: greatamericaStrings,
  [LENDERS.varidi]: varidiStrings,
  [LENDERS.genesis]: genesisStrings,
  [LENDERS.powerpay]: powerpayStrings,
  [LENDERS.ally]: allyStrings,
};
